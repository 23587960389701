import './index.css';

import { ideas } from './ideas.json';

import getRandomInt from './helpers/getRandomInt';

// Get a random idea from the list
const randomIndex = getRandomInt(ideas.length);
const idea = ideas[randomIndex];

const strings = [
  `What if you two ${idea.Verb || 'go out to'} `,
  // Prevent line breaks in the names of the various ideas
  `<span class="name">${idea.Name}...?</span>`,
];

// Show the text
const ideaElem = document.getElementById('ideaText');
ideaElem.innerHTML = strings.join('');
